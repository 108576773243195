import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import { Row, Col } from "antd";

const DonatePage = ({ location }) => {
  return (
    <Layout>
      <div className="index-container">
        <Helmet title={config.siteTitle}>
          <script src="/customDonation.js"></script>
        </Helmet>
        <SEO />
        <h1>Donate to the Holobiont Lab!</h1>
        <Row>
          <Col span={24}>
            <div id="CustomDonationsForm"></div>
            <p>
              Your donation can help us keep up our work to fight and survive
              climate change on a grassroots level!
            </p>
            <p>
              Small donations can keep us supplied with items like energy
              storage batteries, weather-proof equipment enclosures, solar
              panels, and more for our local installations.
            </p>
            <p>
              Large donations will help us compensate people for their labor
              (including ourselves) and pay/hire others returning home from
              incarceration.
            </p>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default DonatePage;
